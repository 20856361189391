import PageContent from "components/page/PageContent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css } from "linaria";
import React from "react";

const sectionContainer = css`
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  background-color: var(--white);
  position: relative;
`;

const sectionInnerRow = css`
  display: flex;
  gap: 103px;
`;

const sectionInnerCol = css`
  width: 50%;
  display: flex;
  flex-grow: 1;
`;

const imageWrapper = css`
  width: 594px;
`;
const imageStyles = css`
  width: 100%;
`;

const textWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 27px;
  h2 {
    font-weight: 900;
    font-size: 32px;
    line-height: 44px;
  }
  ul {
    padding: 0;
    margin: 0;
    h3 {
      font-weight: 900;
      margin-bottom: 27px;
    }
    li {
      margin-left: 27px;
      line-height: 2;
    }
  }
`;

const LearnSection = ({ data }) => {
  const { isMobile } = useIsMobile(800);
  return (
    <section className={sectionContainer}>
      <PageContent>
        <div className={sectionInnerRow}>
          {!isMobile ? (
            <div className={sectionInnerCol}>
              <div className={imageWrapper}>
                <GatsbyImage
                  image={getImage(data.image)}
                  loading="eager"
                  alt={data.title}
                  className={imageStyles}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={sectionInnerCol}>
            <div className={textWrapper}>
              <h2>{data.title}</h2>
              <p>{data.paragraph}</p>
              <ul>
                <h3>{data.list.title}:</h3>
                {data.list.items.map((item, index) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
};

export default LearnSection;
