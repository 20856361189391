import { css } from "linaria";
import PropTypes from "prop-types";
import React from "react";

const pageContentOuterClass = css`
  width: var(--max-width);
  height: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 1480px) {
    width: 100%;
    padding: 0 2.5rem;
  }

  @media (max-width: 1110px) {
    width: 100%;
    padding: 0 1.25rem;
    overflow: hidden;
  }
`;

const pageContentInnerClass = css`
  position: relative;
`;

const flushClass = css`
  @media (max-width: 1110px) {
    padding: 0;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`;

/**
 * Renders a single div that has the correct width for a full page,
 * based on media queries.
 * For example it might be 900px wide on desktop and 100% wide on mobile.
 */
class PageContent extends React.Component {
  render() {
    const rootClass = [
      pageContentOuterClass,
      this.props.rootClass,
      this.props.flush && flushClass,
    ]
      .filter(x => x)
      .join(" ");
    const contentClass = [pageContentInnerClass, this.props.contentClass]
      .filter(x => x)
      .join(" ");
    return (
      <div className={rootClass}>
        <div className={contentClass}>{this.props.children}</div>
      </div>
    );
  }
}
PageContent.propTypes = {
  rootClass: PropTypes.string,
  contentClass: PropTypes.string,
  children: PropTypes.any,
  flush: PropTypes.bool, // flush against the edge of the screen when the window is smaller
};
PageContent.defaultProps = {
  children: null,
  rootClass: null,
  contentClass: null,
  flush: false,
};
export default PageContent;
